

<template>
		<div class="col-md-7 col-sm-7 mt-10 boxmain" v-if="!is_load">
          <section class="container boxrouting">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                  {{$t('general.bus_footer')}}
                </h2>
              </div>
            </div>
          </section>
          <section class="container">

             <form method="post" @submit="mySearch" id="my_search" class='contact-form'>
                      <div class="row">
                      
                        <div class="col-sm-12 form-group ">
                          <input
                            type="text"
                            name="name"
                            class="form-control"
                           
                            autocomplete="off"
                            
                            :placeholder="$t('general.bus')" 
                          
                          />

                          <button class="btn btn-sm btn-warning mt-3" type="submit">
                            <i class="fa fa-search-plus"></i> {{$t('general.search_bus')}}
                          </button>
                        </div>
                        
                         
                      </div>
              </form>

            <div class="row">
              <div class="col-xl-12 col-lg-12">
                
					<h3><strong>  {{$t('general.bus')}}</strong></h3>

					   <table class="table table-bordered" id="tableInvoice">
						<thead>
							<tr>
								<th>
								<p><strong>Số xe&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</strong></p>
								</th>
								<th>
								<p><strong>Lịch tr&igrave;nh&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong></p>
								</th>
								<th>
								<p><strong>Thời gian&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong></p>
								</th>
							</tr>
						</thead>
						<tbody > 
						

              <tr v-for="(v,k) in paginationslice(my_data, 20, page)" :key="k">
								<td>
								<p>{{v.name}}</p>
								</td>
								<td>
								<p>{{v.schedule}}</p>
								</td>
                
								<td>
								<p> {{v.timer=='undefined'? '': v.timer}}</p>
								</td>
							</tr>
								
						</tbody>
					</table>

              </div>

              


            </div>
             <nav class="pagination-nav pdt-30 mt-5" v-if="my_data.length>20">
                  

                    <VuePagination
                    :page-count="Math.floor(my_data.length / 20)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'pagination-list text-center'"
                    :page-class="'page-numbers'"
                  >
                  </VuePagination>
                </nav>
          </section>

        </div>


        <div class="col-md-7 col-sm-7 mt-10 boxmain" v-else>
        	<MyLoadTheme>
          <section class="container boxrouting">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
               	<MyLoading />
              </div>
            </div>
          </section>
          <section class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12">
                
					<h3><strong>   	<MyLoading /></strong></h3>

					 	<MyLoading height="300px" />
              </div>
            </div>
          </section>
     		 </MyLoadTheme>
        </div>


</template>




<script>
//import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";

import VuePagination from "vuejs-paginate";
  import axios from 'axios';
export default {
  
 
  data() {
    return {
      is_load:false,

      myurl:window.my_url,
      my_data:null,
      main:null,

       page: 1,
    
      message:false,
      capcha:null,
      meta:{
        title: "Các tuyến xe bus ",
        description: "Các tuyến xe bus"
      }

      
    };
  },
   metaInfo(){
     return {
        title:this.meta.title,
        description:this.meta.description
     }
  },
  components: {
   VuePagination
    
    
    
  },
  created(){
  	this.is_load=true;
   
     		axios.get(window.my_api+"api/bus/get-buss",
                 {
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
         
                  .then( (res)=>{
                  
                    if(res.status==200){

                         this.main=this.my_data=res.data.data.filter(n=> n.schedule.search("Bến xe Miền Đông")>-1);
                       		this.is_load=false;

                                                                   
                    }else {
                      console.log("ERROR",res);
                    }
                     

                });
    
          
      
  },
  methods:{
       paginationslice(data, pagesize, page) {
      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },
    changePage(e) {
      this.page = e;
    },
    mySearch:function(event){

           event.preventDefault();
        
         let myForm = document.getElementById("my_search");

           let form = new FormData(myForm);


          this.my_data=this.main.filter( (n)=>{
               

                if(form.get("name").length){
                  return    n.schedule.toUpperCase().search(form.get("name").toUpperCase() ) > -1;
                }
                
                 if(form.get("name").length==0){
                      return   true;
                }
                return false;


          });

        return false;
    }


    
  }
}
</script>

